import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { NavParams } from '@ionic/angular';
import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx';
import {PDFGenerator} from '@ionic-native/pdf-generator/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { Toast } from '@awesome-cordova-plugins/toast/ngx';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { Camera, CameraOptions } from '@awesome-cordova-plugins/camera/ngx';

import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
import { CallNumber } from '@awesome-cordova-plugins/call-number/ngx';
import { SMS } from '@awesome-cordova-plugins/sms/ngx';
// import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner/ngx';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';

import { SQLite, SQLiteObject } from '@awesome-cordova-plugins/sqlite/ngx';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
// import { File } from '@awesome-cordova-plugins/file/ngx';
// import { FileTransfer, FileUploadOptions, FileTransferObject } from '@awesome-cordova-plugins/file-transfer/ngx';


@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
  ],
  providers: [
    StatusBar,
    Toast,
    NavParams,
    Keyboard,
    Camera,
    Geolocation,
    CallNumber,BarcodeScanner,
    SMS,SQLite,
    AndroidPermissions,Network,
    // BarcodeScanner,
    PDFGenerator,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
