import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Http, Headers, RequestOptions } from '@angular/http';
import { map, retry } from 'rxjs/operators';
import { LoadingController, AlertController } from '@ionic/angular';
import { Toast } from '@awesome-cordova-plugins/toast/ngx';
import { Observable, Subscription } from 'rxjs'
// import { HttpClient, HttpClientModule } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  link: any;
  task_data: any  =[]
  set_type_data: any
  rider_id: any
  registerData:any
  moreInfo:any
  userData:any
  profiledata: any=[];
  gender:any
  constructor(private router: Router,
    public http: Http,
    public loadingController: LoadingController,
    // public toastController: ToastController,
    private toast: Toast,
    // public http: HttpClient ,
  ) {

   // this.link = 'https://7tracking.com/elections/api.php'
    this.link = 'https://digitalvoter.pk/api.php'
    


  }
  block_code(user_id): Observable<any[]> {
    let postData = new FormData();
    postData.append('type', 'get_block_history');
    postData.append('user_id', user_id);
    return this.http.post(this.link, postData).pipe(map(res => res.json()));
  }
  login(email,password): Observable<any[]> {
    let postData = new FormData();
    postData.append('type', 'login');
    postData.append('email', email);
    postData.append('password', password);
    return this.http.post(this.link, postData).pipe(map(res => res.json()));
  }
  register_img(user_img): Observable<any[]> {
    let postData = new FormData();
    postData.append('type', 'register_img');
    postData.append('img', user_img);
    return this.http.post(this.link, postData).pipe(map(res => res.json()));
  }
  download_data(user_id,block_code): Observable<any[]> {
    let postData = new FormData();
    postData.append('type', 'download_data');
    postData.append('block_code',block_code);
    postData.append('user_id', user_id);
    return this.http.post(this.link, postData).pipe(map(res => res.json()));
  }

  register(name,cinic,image,election_type,password,phone,selectedCity,uc,event,lat,lng): Observable<any[]> {
    let postData = new FormData();
    postData.append('type', 'register');
    postData.append('name', name);
    postData.append('cnic', cinic);
    postData.append('image', image);
    postData.append('election_type', election_type);
    postData.append('password', password);
    postData.append('phone', phone);
    postData.append('city', selectedCity);
    postData.append('event_id', event);
    postData.append('lat', lat);
    postData.append('lng', lng);
    return this.http.post(this.link, postData).pipe(map(res => res.json()));
  }
update_password(old_password,new_password,user_id): Observable<any[]> {
    let postData = new FormData();
    postData.append('type', 'change_password');
    postData.append('old_password', old_password);
    postData.append('new_password', new_password);
    postData.append('user_id', user_id);
    return this.http.post(this.link, postData).pipe(map(res => res.json()));
  }
  get_cnic(cnic,user_id): Observable<any[]> {
    let postData = new FormData();
    postData.append('type', 'get_cnic');
    postData.append('cnic', cnic);
    postData.append('user_id', user_id);
    return this.http.post(this.link, postData).pipe(map(res => res.json()));
  }
  addPhone(phone,user_id,cnic): Observable<any[]> {
    let postData = new FormData();
    postData.append('type', 'add_data');
    postData.append('table_name', 'user_phones');
    postData.append('phone', phone);
    postData.append('cnic', cnic);
    postData.append('user_id', user_id);
    return this.http.post(this.link, postData).pipe(map(res => res.json()));
  }
  getList(): Observable<any[]> {
    let postData = new FormData();
    postData.append('type', 'get_list');
    return this.http.post(this.link, postData).pipe(map(res => res.json()));
  }
  get_history(user_id): Observable<any[]> {
    let postData = new FormData();
    postData.append('type', 'get_history');
    postData.append('user_id', user_id);
    return this.http.post(this.link, postData).pipe(map(res => res.json()));
  } 
   profile(user_id): Observable<any[]> {
    let postData = new FormData();
    postData.append('type', 'profile');
    postData.append('user_id', user_id);
    return this.http.post(this.link, postData).pipe(map(res => res.json()));
  } 
  update_profile(name,img,user_id): Observable<any[]> {
    let postData = new FormData();
    postData.append('type', 'update_user');
    postData.append('user_id', user_id);
    postData.append('name', name);
    postData.append('image', img);
    return this.http.post(this.link, postData).pipe(map(res => res.json()));
  } 
  marked_hami(marked,event_id,user_id,cnic) : Observable<any[]> {
    let postData = new FormData();
    postData.append('type', 'add_data');
    postData.append('table_name', 'user_votes_marked');
    postData.append('user_id', user_id);
    postData.append('event_id', event_id);
    postData.append('cnic', cnic);
    postData.append('marked', marked);
    return this.http.post(this.link, postData).pipe(map(res => res.json()));
  } 
  get_search_code(code,user_id): Observable<any[]> {
    let postData = new FormData();
    postData.append('type', 'get_block_code');
    postData.append('block_code', code)
    postData.append('user_id', user_id)
    return this.http.post(this.link, postData).pipe(map(res => res.json()));
  }
  set_data(data) {
    this.task_data = data
  }
  get_task_data() {
    return this.task_data
  }
  set_gender(data){
    this.gender = data
  }
  get_gender(){
    return this.gender
  }
  setRegisterData_(data) {
    this.registerData = data
  }
  getRegisterData_() {
    return this.registerData
  }
  setMoreInfo_(data){
    this.moreInfo = data
  }
  getMoreInfo_(){
    return this.moreInfo
  }
  set_userData(data){
    this.userData = data
  }
  getUserData(){
    return this.userData
  }

  set_profile_data(profile){
    this.profiledata = profile 
  }

  get_profile_data(){
    return this.profiledata
  }
  

   


  async showmessage(message) {
    this.toast.show(message, '3000', 'bottom').subscribe(
      toast => {
        console.log(toast);
      }
    );
  }

}

