import { Component,HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { ApiService } from './api/api.service';
import { Location } from '@angular/common';
import { AlertController } from '@ionic/angular';
import { Network } from '@awesome-cordova-plugins/network/ngx';
// import { SwUpdate } from '@angular/service-worker';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  rider_id: any;
  user_id: any;
  profile: any;


  constructor(public router: Router,
    private platform: Platform,
    private statusBar: StatusBar,
    public service: ApiService,
    public alertCtrl : AlertController,
    private _location: Location,) {
      
    this.init()
      this.checkmove()
      this.getprofile()
    this.hardware_btn()
  

  }

  

  init() {
    // this.statusBar.styleDefault();
    this.platform.ready().then(async () => {
      setTimeout(() => {
        SplashScreen.hide({
          fadeOutDuration: 500
        });
        this.statusBar.show()
        this.statusBar.backgroundColorByHexString('#ffffff')
      }, 2000)
    })
  }

  checkmove() {
    this.rider_id = window.localStorage.getItem('user_id')
    if (this.rider_id != null && this.rider_id != undefined) {
      this.router.navigate(['tabs/block'])
    }
    else {
      this.router.navigate(['login'])
    }
  }
  getprofile() {
    this.user_id = window.localStorage.getItem('user_id')
    this.service.profile(this.user_id).subscribe(res => {
      console.log(res)
      this.profile = res['profile']
      this.service.set_profile_data(res['profile'])
    });
  }

  hardware_btn(){

    this.platform.backButton.subscribeWithPriority(10, (processNextHandler) => {
      console.log('Back press handler!');
      console.log(this._location.isCurrentPathEqualTo);

      if (this._location.isCurrentPathEqualTo('/tabs/block') ) {
        // Show Exit Alert!
        
          console.log('Show Exit Alert!');
          processNextHandler();
          this.showExitConfirm();
      }else if (this._location.isCurrentPathEqualTo('/login')){
        console.log('Show Exit Alert!');
        processNextHandler();
      } else {
        // Navigate to back page
        console.log('Navigate to back page');
        this._location.back();

      }

    });

    this.platform.backButton.subscribeWithPriority(5, () => {
      console.log('Handler called to force close!');
      this.alertCtrl.getTop().then(r => {
        if (r) {
          navigator['app'].exitApp();
        }
      }).catch(e => {
        console.log(e);
      })
    });
  }
  showExitConfirm() {
    this.alertCtrl.create({
      cssClass: 'my-alert',
      header: 'App termination',
      message: 'Do you want to close the app?',
      mode:'ios',
      backdropDismiss: false,
      buttons: [{
        text: 'Stay',
        role: 'cancel',
        handler: () => {
          console.log('Application exit prevented!');
        }
      }, {
        text: 'Exit',
        handler: () => {
          navigator['app'].exitApp();
        }
      }]
    })
      .then(alert => {
        alert.present();
      });
  }

}
