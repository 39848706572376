import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [

  

  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule)
  },
 
 
  {
    path: 'register',
    loadChildren: () => import('./register/register.module').then(m => m.RegisterPageModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule)
  },
  {
    path: 'personal-info',
    loadChildren: () => import('./personal-info/personal-info.module').then(m => m.PersonalInfoPageModule)
  },

  {
    path: 'done',
    loadChildren: () => import('./done/done.module').then(m => m.DonePageModule)
  },
  {
    path: 'search-result',
    loadChildren: () => import('./search-result/search-result.module').then(m => m.SearchResultPageModule)
  },
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'block',
    loadChildren: () => import('./block/block.module').then(m => m.BlockPageModule)
  },
  {
    path: 'family-detail',
    loadChildren: () => import('./family-detail/family-detail.module').then(m => m.FamilyDetailPageModule)
  },
  {
    path: 'block-houses',
    loadChildren: () => import('./block-houses/block-houses.module').then(m => m.BlockHousesPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfilePageModule)
  },
  {
    path: 'camera-modal',
    loadChildren: () => import('./camera-modal/camera-modal.module').then( m => m.CameraModalPageModule)
  },
  {
    path: 'more-info',
    loadChildren: () => import('./more-info/more-info.module').then( m => m.MoreInfoPageModule)
  },
  {
    path: 'city-modal',
    loadChildren: () => import('./city-modal/city-modal.module').then( m => m.CityModalPageModule)
  },
  {
    path: 'uc-modal',
    loadChildren: () => import('./uc-modal/uc-modal.module').then( m => m.UCModalPageModule)
  },
  {
    path: 'security-pin',
    loadChildren: () => import('./security-pin/security-pin.module').then( m => m.SecurityPinPageModule)
  },
  {
    path: 'events',
    loadChildren: () => import('./events/events.module').then( m => m.EventsPageModule)
  },
  {
    path: 'saved-history',
    loadChildren: () => import('./saved-history/saved-history.module').then( m => m.SavedHistoryPageModule)
  },
  {
    path: 'add-phone',
    loadChildren: () => import('./add-phone/add-phone.module').then( m => m.AddPhonePageModule)
  },
  {
    path: 'search-code',
    loadChildren: () => import('./search-code/search-code.module').then( m => m.SearchCodePageModule)
  },
  {
    path: 'election-type',
    loadChildren: () => import('./election-type/election-type.module').then( m => m.ElectionTypePageModule)
  },
  {
    path: 'contact-modal',
    loadChildren: () => import('./contact-modal/contact-modal.module').then( m => m.ContactModalPageModule)
  },
  {
    path: 'product/:id',
    loadChildren: () => import('./pages/details/details.module').then( m => m.DetailsPageModule)
  },
  {
    path: 'sync-data',
    loadChildren: () => import('./sync-data/sync-data.module').then( m => m.SyncDataPageModule)
  },


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
